import service from '@/middleware'

const orderServiceApi = {
  async create (dto) {
    return service.http.post(`/api/order/create`, dto)
  },
  async getAll (pageSize, pageNumber) {
    return service.http.get(`/api/order/all?pageSize=${pageSize}&pageNumber=${pageNumber}`)
  },
  async getSectors () {
    return service.http.get(`/api/order/sector`)
  },
  async getSectorsTypes (sectorId) {
    return service.http.get(`/api/order/sectorTypes?id=${sectorId}`)
  },
  async getTotalCount () {
    const result = await service.http.get(`/api/order/total`)
    return result
  },
  async update (dto) {
    const result = await service.http.put('/api/order/update', dto)
    return result
  },
  async send (dto) {
    const result = await service.http.post('/api/order/send', dto)
    return result
  },
  async justSend (dto) {
    const result = await service.http.post('/api/order/justSend', dto)
    return result
  },
  async updateAndSend (dto) {
    const result = await service.http.put('/api/order/updateAndSend', dto)
    return result
  },
  async delete (id) {
    const result = await service.http.delete(`/api/order/${id}`)
    return result
  },
  async multipleDelete (ids) {
    const result = await service.http.put(`/api/order/multiple-delete`, ids)
    return result
  },
  // -------------------
  // MONITOR A BORDO >>>
  async getProductiveChart (thingsId, from, to, sectorId, activityId, typeKey) {
    const body = { thingsId, from, to, sectorId, activityId, typeKey }
    const result = await service.http.post('/api/on-board-monitor-report/productive-chart', body)
    return result
  },
  async getPauseChart (thingsId, from, to, sectorId, activityId, typeKey) {
    const body = { thingsId, from, to, sectorId, activityId, typeKey }
    const result = await service.http.post('/api/on-board-monitor-report/pause-chart', body)
    return result
  },
  async getStateChart (thingsId, from, to, sectorId, activityId, typeKey) {
    const body = { thingsId, from, to, sectorId, activityId, typeKey }
    const result = await service.http.post('/api/on-board-monitor-report/state-chart', body)
    return result
  },
  async getSummaryDefered (body) {
    const result = await service.http.post('/api/on-board-monitor-report/deferred', body)
    return result
  },
  async getSummary (body) {
    const result = await service.http.post('/api/on-board-monitor-report/not-deferred', body)
    return result
  },
  async getDetail (thingId, from, to, sectorId, activityId, typeKey) {
    const body = { thingId, from, to, sectorId, activityId, typeKey }
    const result = await service.http.post('/api/on-board-monitor-report/detail', body)
    return result
  }
}

export default orderServiceApi
