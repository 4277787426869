import i18n from '@/i18n'
import SelectorDialog from '@/components/commons/selector/dialog/SelectorDialog.vue'
import { filterRulesMapped } from '@/tools/filterRules'
import thingApi from '@/api/thing.api'
import orderServiceApi from '@/api/orderService.api'
import driveUnitApi from '@/api/drive-unit.api'
import activityApi from '@/api/activity.api'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { DialogType, SnackbarStyle, stateConstants } from '@/constants/constants'
import equal from 'deep-equal'
import ListTreeViewComponent from '@/components/commons/list/tree-view/ListTreeViewComponent.vue'
import deepcopy from 'deepcopy'
import { getItemById } from '../../tools/functions'

export default {
  name: 'ServiceOrderABMComponent',
  components: {
    ListTreeViewComponent,
    SelectorDialog
  },
  data: () => ({
    /**
     *   "_id" : ObjectId("5e2efe0997ad763e58f7da46"),
     *   "name": "VINCULACION-1"
     *   "v1" : "5e163867e70a3b6cdcf7a76f",
     *   "v2" : "5e16387ce70a3b6cdcf7a770",
     *   "v1State" : "5e2ef78b730ea6907dae1ca9",
     *   "v2State" : "5e2ef794730ea6907dae1caf",
     *   "permanent" : "false",
     *   "timeTolerance" : 6,
     *   "distanceTolerance" : 6,
     *   "timePermanence" : 9,
     *   "exceptions" : [],
     *   "alert" : 1,
     *   "active" : false
     */
    tableHeaders: [],
    tableFilterRules: {},
    tableData: [],
    tableDataKey: 'id',
    rowActions: [
      {
        id: 1,
        name: i18n.t('modify'),
        color: 'blue',
        icon: 'fa-pen'
      },
      {
        id: 2,
        name: i18n.t('delete'),
        color: 'red',
        icon: 'fa-trash-alt'
      },
      {
        id: 3,
        name: i18n.t('serviceOrder.send'),
        color: 'green',
        icon: 'fa-paper-plane',
        disable (item) {
          return !!item.commandId
        }
      },
      {
        id: 5,
        name: i18n.t('serviceOrder.reSend'),
        color: 'blue',
        icon: 'fa-paper-plane',
        disable (item) {
          return !(item.commandId)
        }
      },
      {
        id: 4,
        name: i18n.t('serviceOrder.clone'),
        color: 'grey',
        icon: 'fa-clone'
      }
    ],
    tableComponentButtons: [],
    queryPageSize: undefined,
    queryPageNumber: undefined,
    queryId: undefined,
    pageTotal: 0,
    exceptions: [],
    showEditDialog: false,
    update: false,
    editDialogTitle: i18n.t('serviceOrder.editDialog.titleNew'),
    loadingTable: true,
    validForm: false,
    rpmMask: '####',
    velocityMask: '###.#',
    anchorMask: '#.#',
    marchMask: 'XXX',
    activityAplicate: false,
    validationRules: {
      required: (value) => !!value || `${i18n.t('required')}`,
      requiredDefault: (value, defaultValue) => !!value || value === defaultValue || `${i18n.t('required')}`,
      min: (value, minValue) => value >= minValue || `${i18n.t('minNumber', { min: minValue })}`,
      max: (value, maxValue) => value <= maxValue || `${i18n.t('maxNumber', { max: maxValue })}`,
      requiredVinculationPermanence: (value, timeConfiguration) => (timeConfiguration && !!value) || (!timeConfiguration) || `${i18n.t('required')}`,
      nameWhenUpdate: (value, ruleToUpdate) => (ruleToUpdate && value !== ruleToUpdate.name) || !ruleToUpdate || `${i18n.t('vinculation.differentNameWhenUpdate')}`
    },
    sectorSelectorDialogModel: {
      normalizer: (node) => {
        return {
          id: node.sectorId,
          key: node.key,
          label: node.sector,
          children: node.children
        }
      },
      data: [],
      selected: null,
      show: false,
      title: i18n.t('serviceOrder.editDialog.titleSector'),
      singleSelect: true,
      selectAll: false,
      saveDisabled: true
    },
    typeSelectorDialogModel: {
      normalizer: (node) => {
        return {
          id: node.typeId.concat('@').concat(node.type),
          label: node.type,
          children: undefined
        }
      },
      data: [],
      selected: null,
      show: false,
      title: i18n.t('serviceOrder.editDialog.titleType'),
      singleSelect: true,
      selectAll: false,
      saveDisabled: true
    },
    subTypeSelectorDialogModel: {
      normalizer: (node) => {
        return {
          id: node._id,
          label: node.name,
          children: undefined
        }
      },
      data: [],
      selected: null,
      show: false,
      title: i18n.t('serviceOrder.editDialog.titleVehicle'),
      singleSelect: true,
      selectAll: false,
      saveDisabled: true
    },
    placeSelectorDialogModel: {
      normalizer: (node) => {
        return {
          id: node.id,
          label: node.name,
          children: node.children
        }
      },
      data: [],
      selected: null,
      show: false,
      title: i18n.t('serviceOrder.editDialog.titlePlace'),
      singleSelect: true,
      selectAll: false,
      saveDisabled: true,
      disableBranch: true
    },
    activitiesSelectorDialogModel: {
      normalizer: (node) => {
        return {
          id: node.id,
          label: node.name,
          children: node.children
        }
      },
      data: [],
      selected: null,
      show: false,
      title: i18n.t('serviceOrder.editDialog.titleActivity'),
      singleSelect: true,
      selectAll: false,
      saveDisabled: true
    },
    sectorModel: null,
    typeModel: null,
    subTypeModel: null,
    placeModel: null,
    activityModel: null,
    sectorModelID: null,
    typeModelID: null,
    subTypeModelID: null,
    placeModelID: null,
    activityModelID: null,
    rpmModel: null,
    marchModel: null,
    velocityModel: null,
    anchorModel: null,
    unitSwitchModel: true,
    unitSwitchLabel: i18n.t('serviceOrder.editDialog.unitSwitch'),
    loadingRPM: false,
    loadingVelocity: false,
    loadingAnchor: false,
    transportAndCamion: false,
    sendLoading: false
  }),
  computed: {
    ...mapGetters('messaging', {
      getOSData: 'getOSData'
    }),
    disableSaveButton () {
    },
    disableSelectButton () {
      if (this.ruleToUpdate) {
        return true
      } else {
        return false
      }
    },
    disableTypeSelectButton () {
      if (!this.sectorModelID) {
        return true
      } else {
        return false
      }
    },
    disableSubtypeSelectButton () {
      if (!this.typeModelID) {
        return true
      } else {
        return false
      }
    },
    /**
     * Cuando se actualiza una regla, deshabilita el botón si no se hicieron cambios en el formulario
     */
    disableWhenUpdate () {
      return (
        this.ruleToUpdate &&
        equal(this.ruleToUpdate.exceptions, this.exceptions)
      )
    }
  },
  created () {
    // Si hay query params, los setea para incluirlos en la llamada al backend, de lo contrario, busca todas las reglas de vinculación
    if (this.$route.query) {
      this.queryPageSize = this.$route.query.pageSize ? Number(this.$route.query.pageSize) : 20
      this.queryPageNumber = this.$route.query.pageNumber ? Number(this.$route.query.pageNumber) : 1
    }
    // Valida que los qery params sean correctos y completos
    if ((this.queryPageSize && this.queryPageSize <= 0) || (this.queryPageNumber && this.queryPageNumber <= 0) || (this.queryPageNumber && !this.queryPageSize) || (!this.queryPageNumber && this.queryPageSize)) {
      this.showSnackbar({
        visible: true,
        text: this.$t('error.invalidQueryParams'),
        timeout: 6000,
        style: SnackbarStyle.ERROR
      })
      this.queryPageSize = undefined
      this.queryPageNumber = undefined
    }
    // Botón de eliminación múltiple
    this.tableComponentButtons.push(
      {
        id: 'edit-dialog',
        tooltip: i18n.t('add'),
        icon: 'add',
        action: this.showDialog.bind(this),
        selectedData: false
      }
    )
    // Obtiene todas las cosas de la base de datos
    // Obtener todas las vinculaciones y la vinculación para editar (si se utilzia el query param)
    // this.resetFields()
    this.getData()
    if (this.queryId) {
      this.getVinculationById(this.queryId)
    }
  },
  methods: {
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    ...mapMutations('dialog', {
      openDialog: 'openDialog',
      closeDialog: 'closeDialog'
    }),
    /**
     * Resize de los split panel
     */
    resize () {
    },
    /**
     * Click en una fila
     * @param {*} data
     */
    clickRow () {
    },
    /**
     * Refrescar la data
     */
    refreshTable () {
      this.getData(true)
    },
    /**
     * Se ejecuta cuando cambian los datos de la tabla (por búsqueda, filtrado, agregar/quitar columnas, etc.)
     * @param {*} newData
     */
    dataChangeEventHandler () {
    },
    cancelDialogAction () {
      this.sectorSelectorDialogModel.show = false
      this.typeSelectorDialogModel.show = false
      this.subTypeSelectorDialogModel.show = false
      this.placeSelectorDialogModel.show = false
      this.activitiesSelectorDialogModel.show = false
    },
    getData (refresh = false) {
      this.loadingTable = true
      orderServiceApi.getTotalCount().then(response => {
        this.pageTotal = Number(response.data)
      })
      orderServiceApi.getAll(this.queryPageSize, this.queryPageNumber).then(response => {
        this.tableData.cleanAndUpdate(response.data.data)
        if (!refresh) {
          this.tableHeaders.cleanAndUpdate(response.data.headers)
          this.setTableFilterRules()
        }
        this.loadingTable = false
      })
    },
    setTableFilterRules () {
      this.tableFilterRules = {}
      this.tableHeaders.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    getSectors () {
      return orderServiceApi.getSectors().then(response => {
        response.data.map(e => {
          e.id = e.sectorId
          return e
        })
        this.sectorSelectorDialogModel.data.cleanAndUpdate(response.data)
      })
    },
    getTypes () {
      const sector = this.sectorSelectorDialogModel.data.find(elem => elem.sectorId === this.sectorModelID)
      if (sector) {
        const types = sector.types.map(e => {
          e.id = e.typeId
          return e
        })
        this.typeSelectorDialogModel.data.cleanAndUpdate(types)
      } else {
        this.typeSelectorDialogModel.data = []
      }
    },
    getSubTypes () {
      if (this.typeModelID) {
        let request
        if (typeof this.typeSelectorDialogModel.selected === 'string') {
          request = [this.getTypeId(this.typeModelID)]
        } else {
          request = this.getTypeId(this.typeModelID)
        }
        return thingApi.getByTypeId(request[0]).then(response => {
          response.data.map(e => {
            e.id = e._id
            return e
          })
          this.subTypeSelectorDialogModel.data.cleanAndUpdate(response.data)
        })
      }
    },
    getPlaces () {
      return driveUnitApi.getStructureByEnterprise().then(response => {
        this.placeSelectorDialogModel.data.cleanAndUpdate(response.structure)
      })
    },
    getActivities () {
      return activityApi.getAllForSelector('FARM').then(response => {
        this.activitiesSelectorDialogModel.data.cleanAndUpdate(response.data)
      })
    },
    /**
     * Resetea los campos del formulario de creación/actualización
     */
    async resetFields () {
      this.ruleToUpdate = null
      this.sectorModel = null
      this.typeModel = null
      this.subTypeModel = null
      this.placeModel = null
      this.rpmModel = null
      this.velocityModel = null
      this.anchorModel = null
      this.activityModel = null
      this.marchModel = null
      this.sectorModelID = null
      this.typeModelID = null
      this.subTypeModelID = null
      this.placeModelID = null
      this.activityModelID = null
      this.sectorSelectorDialogModel.selected = null
      this.typeSelectorDialogModel.selected = null
      this.placeSelectorDialogModel.selected = null
      this.subTypeSelectorDialogModel.selected = null
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      this.showEditDialog = false
      this.editDialogTitle = i18n.t('serviceOrder.editDialog.titleNew')
      this.activityAplicate = false
      this.transportAndCamion = false
      this.sendLoading = false
      this.update = false
    },
    async delete (data) {
      await orderServiceApi.delete(data.id)
      this.showSnackbar({
        visible: true,
        text: this.$t('serviceOrder.successDeleteMessage', { identificator: data.identificator }),
        timeout: 6000,
        style: SnackbarStyle.SUCCESS
      })
      this.getData(true)
    },
    /**
     * A partir de una orden ya creada, envía o reenvía el comando
     * @param {*} data
     */
    async sendOrder (data) {
      // Primero se arma el dto a partir de los datos de la tabla
      const dto = {
        _id: data.id,
        commandId: data.commandId,
        identificator: data.identificator,
        sector: data.sectorId,
        typeId: data.typeId,
        thingId: data.thingId,
        unitOrPlace: data.unitOrPlaceId,
        rpm: data.rpm,
        velocity: data.velocity,
        anchor: data.anchor,
        march: data.march
      }
      // Se envía la orden
      await orderServiceApi.justSend(dto)
      this.showSnackbar({
        visible: true,
        text: this.$t('serviceOrder.editDialog.successSendMessage'),
        timeout: 6000,
        style: SnackbarStyle.SUCCESS
      })
      this.getData(true)
    },
    async multipleDelete (data) {
      this.openDialog({
        title: this.$t('delete'),
        text: this.$t('serviceOrder.multipleDeleteMessage'),
        type: DialogType.QUESTION,
        overlayOpacity: 0.5,
        actionButtons: true,
        yesAction: async () => {
          const ids = data.map(e => e.id)
          await orderServiceApi.multipleDelete(ids)
          this.getData(true)
          this.$refs['order-table'].resetSelected()
        },
        noAction: () => {
        }
      })
    },
    getTypeId (key) {
      const typeId = key.substring(
        0,
        key.lastIndexOf('@')
      )
      return typeId
    },
    async loadFieldsToClone (data) {
      await this.getSectors()
      this.sectorModel = data.sector
      this.typeModel = data.type
      this.subTypeModel = data.thingName
      this.placeModel = data.unitOrPlace
      this.rpmModel = data.rpm
      this.marchModel = data.march
      this.velocityModel = data.velocity
      this.anchorModel = data.anchor
      this.activityModel = data.activity
      if (data.activity) {
        this.activityAplicate = true
      }
      const sectorSelect = {
        id: data.sectorId,
        name: data.sector,
        selected: false
      }
      this.sectorSelectorDialogModel.selected = sectorSelect.id
      this.sectorModelID = sectorSelect.id

      this.getTypes()
      const typeSelect = {
        id: data.typeId.concat('@').concat(data.type),
        name: data.type,
        selected: false
      }

      this.typeSelectorDialogModel.selected = typeSelect.id
      this.typeModelID = typeSelect.id

      const activitySelect = {
        id: data.activityId,
        name: data.activity,
        selected: false
      }
      this.activitiesSelectorDialogModel.selected = activitySelect.id
      this.activityModelID = activitySelect.id

      this.getPlaces()
      const placeSelect = {
        id: data.unitOrPlaceId,
        name: data.unitOrPlace,
        selected: false
      }
      this.placeSelectorDialogModel.selected = placeSelect.id
      this.placeModelID = placeSelect.id

      this.getSubTypes()
      const vehiclesSelect = {
        id: data.thingId,
        name: data.thingName,
        selected: false
      }
      this.subTypeSelectorDialogModel.selected = vehiclesSelect.id
      this.subTypeModelID = vehiclesSelect.id

      this.configActicity()

      this.configEditables()

      this.showDialog()
    },
    async loadFields (data) {
      await this.getSectors()
      this.ruleToUpdate = data
      this.sectorModel = data.sector
      this.typeModel = data.type
      this.subTypeModel = data.thingName
      this.placeModel = data.unitOrPlace
      this.rpmModel = data.rpm
      this.marchModel = data.march
      this.velocityModel = data.velocity
      this.anchorModel = data.anchor
      this.activityModel = data.activity
      if (data.activity) {
        this.activityAplicate = true
      }
      const sectorSelect = {
        id: data.sectorId,
        name: data.sector,
        selected: false
      }
      this.sectorSelectorDialogModel.selected = sectorSelect.id
      this.sectorModelID = sectorSelect.id

      this.getTypes()
      const typeSelect = {
        id: data.typeId.concat('@').concat(data.type),
        name: data.type,
        selected: false
      }

      this.typeSelectorDialogModel.selected = typeSelect.id
      this.typeModelID = typeSelect.id

      const activitySelect = {
        id: data.activityId,
        name: data.activity,
        selected: false
      }
      this.activitiesSelectorDialogModel.selected = activitySelect.id
      this.activityModelID = activitySelect.id

      this.getPlaces()
      const placeSelect = {
        id: data.unitOrPlaceId,
        name: data.unitOrPlace,
        selected: false
      }
      this.placeSelectorDialogModel.selected = placeSelect.id
      this.placeModelID = placeSelect.id

      this.getSubTypes()
      const vehiclesSelect = {
        id: data.thingId,
        name: data.thingName,
        selected: false
      }
      this.subTypeSelectorDialogModel.selected = vehiclesSelect.id
      this.subTypeModelID = vehiclesSelect.id

      this.configActicity()

      this.configEditables()

      this.showDialog()
    },
    rowButtonClicked (button, data) {
      switch (button.id) {
        case 1:
          // TO DO: agregar el id como query param
          if (data.gestyaStateKey !== stateConstants.NOT_SEND) {
            this.showSnackbar({
              visible: true,
              text: this.$t('serviceOrder.orderJustSend', { identificator: data.identificator }),
              timeout: 6000,
              style: SnackbarStyle.WARNING
            })
            return
          }
          this.editDialogTitle = i18n.t('serviceOrder.editDialog.titleEdit')
          this.update = true
          this.loadFields(data)
          break
        case 2:
          if (data.gestyaStateKey !== stateConstants.NOT_SEND) {
            this.showSnackbar({
              visible: true,
              text: this.$t('serviceOrder.orderJustSendByDelete', { identificator: data.identificator }),
              timeout: 6000,
              style: SnackbarStyle.WARNING
            })
            return
          }
          this.openDialog({
            title: this.$t('delete'),
            text: this.$t('serviceOrder.deleteMessage', { identificator: data.identificator }),
            type: DialogType.QUESTION,
            overlayOpacity: 0.5,
            actionButtons: true,
            yesAction: () => {
              this.delete(data)
            },
            noAction: () => {
            }
          })
          break
        case 3:
          if (data.gestyaStateKey !== stateConstants.NOT_SEND && data.gestyaStateKey !== stateConstants.DELIVERED && data.gestyaStateKey !== stateConstants.SEND) {
            this.showSnackbar({
              visible: true,
              text: this.$t('serviceOrder.orderJustSend', { identificator: data.identificator }),
              timeout: 6000,
              style: SnackbarStyle.WARNING
            })
            return
          }
          this.openDialog({
            title: this.$t('serviceOrder.send'),
            text: this.$t('serviceOrder.sendMessage', { identificator: data.identificator }),
            type: DialogType.QUESTION,
            overlayOpacity: 0.5,
            actionButtons: true,
            yesAction: () => {
              this.sendOrder(data)
            },
            noAction: () => {
            }
          })
          break
        case 4:
          this.editDialogTitle = i18n.t('serviceOrder.clone')
          this.loadFieldsToClone(data)
          break
        case 5:
          if (data.gestyaStateKey !== stateConstants.NOT_SEND && data.gestyaStateKey !== stateConstants.DELIVERED && data.gestyaStateKey !== stateConstants.SEND) {
            this.showSnackbar({
              visible: true,
              text: this.$t('serviceOrder.orderJustSend', { identificator: data.identificator }),
              timeout: 6000,
              style: SnackbarStyle.WARNING
            })
            return
          }
          this.openDialog({
            title: this.$t('serviceOrder.send'),
            text: this.$t('serviceOrder.sendMessage', { identificator: data.identificator }),
            type: DialogType.QUESTION,
            overlayOpacity: 0.5,
            actionButtons: true,
            yesAction: () => {
              this.sendOrder(data)
            },
            noAction: () => {
            }
          })
          break
        default:
          console.log('default')
      }
    },
    clickOutside () {
      this.cancel()
    },
    cancel () {
      this.resetFields()
    },
    /**
     * CREATE:
     * Crea una orden de servicio y la envía (botón ENVIAR del diálogo de creación)
     * UPDATE:
     * Actualiza una orden de servicio y actualiza el comando (botón ACTUALIZAR del diálogo de edición)
     * Si el comando ya fue enviado, entonces se lanza una excepción
     */
    sendAndCreate () {
      this.showEditDialog = false
      let serviceDto = this.getServiceDto(this.ruleToUpdate != null)
      this.sendLoading = true
      if (this.ruleToUpdate) {
        orderServiceApi.updateAndSend(serviceDto).then(() => {
          this.showSnackbar({ visible: true, text: this.$t('serviceOrder.editDialog.successSendMessage', { identificator: this.ruleToUpdate.identificator }), timeout: 6000, style: SnackbarStyle.SUCCESS })
          this.sendLoading = false
          this.resetFields()
          this.getData(true)
        }).catch(() => {
          this.sendLoading = false
        })
      } else {
        // SEND
        orderServiceApi.send(serviceDto).then(() => {
          this.sendLoading = false
          this.showSnackbar({
            visible: true,
            text: this.$t('serviceOrder.editDialog.successSendMessage'),
            timeout: 6000,
            style: SnackbarStyle.SUCCESS
          })
          this.resetFields()
          this.getData()
        }).catch(() => {
          this.sendLoading = false
        })
      }
    },
    showDialog () {
      this.showEditDialog = true
    },
    /**
     * Crea una orden de servicio nueva
     *
     * TO DO: se utiliza solamente en el proceso de creación, debería sacarse update
     * porque no se utiliza
     */
    save () {
      const serviceDto = this.getServiceDto(this.ruleToUpdate != null)

      if (this.ruleToUpdate) {
        // UPDATE
        orderServiceApi.update(serviceDto).then(() => {
          this.showSnackbar({ visible: true, text: this.$t('serviceOrder.editDialog.successUpdateMessage', { identificator: this.ruleToUpdate.identificator }), timeout: 6000, style: SnackbarStyle.SUCCESS })
          this.resetFields()
          this.getData(true)
        })
      } else {
        // CREATE
        orderServiceApi.create(serviceDto).then(() => {
          this.showSnackbar({
            visible: true,
            text: this.$t('serviceOrder.editDialog.successCreateMessage', { number: 1 }),
            timeout: 6000,
            style: SnackbarStyle.SUCCESS
          })
          this.resetFields()
          this.getData()
        })
      }
    },
    /**
     * Obtener el dto para crear/actualizar una orden de servicio
     * @param {*} update
     */
    getServiceDto (update = true) {
      const dto = {}

      // si es para actualizar, se agrega el _id, el identifcator y el id_comando
      if (update) {
        dto._id = this.ruleToUpdate.id
        dto.commandId = this.ruleToUpdate.commandId
        dto.identificator = this.ruleToUpdate.identificator
      }

      dto.sector = this.sectorModelID
      dto.typeId = this.typeModelID
      dto.thingId = this.subTypeModelID
      dto.unitOrPlace = this.placeModelID
      if (this.activityModelID) {
        dto.activity = this.activityModelID
      }
      dto.rpm = this.rpmModel
      dto.velocity = this.velocityModel
      dto.anchor = this.anchorModel
      dto.march = this.marchModel
      return dto
    },
    async selectSector () {
      // obtengo los sectors
      this.getSectors()
      this.sectorSelectorDialogModel.show = true
    },
    async selectType () {
      // obtengo los sectors
      this.getTypes()
      this.typeSelectorDialogModel.show = true
    },
    async selectSubtype () {
      // obtengo los sectors
      this.getSubTypes()
      this.subTypeSelectorDialogModel.show = true
    },
    async selectPlace () {
      // obtengo los sectors
      this.getPlaces()
      this.placeSelectorDialogModel.show = true
    },
    async selectActivity () {
      // obtengo los sectors
      this.activitiesSelectorDialogModel.show = true
      this.getActivities()
    },

    async configureData (data) {
      if (this.sectorModel === 'Cosecha' || (this.sectorModel === 'FINCA' && this.typeModel === 'Cosechadoras')) {
        this.loadingRPM = true
        this.loadingVelocity = true
        this.loadingAnchor = true
        return driveUnitApi.getById(data.id).then(response => {
          this.loadingRPM = false
          this.loadingVelocity = false
          this.loadingAnchor = false
          this.velocityModel = response.data.properties.harvestSpeed
          this.anchorModel = response.data.properties.headWidth
        })
      }
    },
    async configActicity () {
      if (this.sectorModel === 'FINCA' && this.typeModel === 'Tractores') {
        this.activityAplicate = true
      } else {
        this.activityAplicate = false
        this.activitiesSelectorDialogModel.selected = null
        this.activityModelID = null
      }
    },
    async configEditables () {
      if (this.sectorModel === 'Transporte' && this.typeModel === 'Camiones') {
        this.transportAndCamion = true
        this.rpmModel = null
        this.anchorModel = null
        this.marchModel = null
      } else {
        this.transportAndCamion = false
      }
    },
    notImplemented () {
      this.showSnackbar({ visible: true, text: this.$t('notImplemented'), timeout: 6000, style: SnackbarStyle.WARNING })
    },
    saveDialogAction (items) {
      const itemsCopy = deepcopy(items)
      const selectedItem = []
      if (this.sectorSelectorDialogModel.show) {
        getItemById(this.sectorSelectorDialogModel.data, itemsCopy, selectedItem)
        const lastSelection = this.sectorModel
        this.sectorModel = deepcopy(selectedItem[0].sector)
        this.sectorModelID = this.sectorSelectorDialogModel.selected
        if (this.sectorModel !== lastSelection) {
          this.typeModel = undefined
          this.typeSelectorDialogModel.selected = null
          this.subTypeModel = undefined
          this.subTypeSelectorDialogModel.selected = null
        }
        this.sectorSelectorDialogModel.show = false
      } else if (this.typeSelectorDialogModel.show) {
        getItemById(this.typeSelectorDialogModel.data, this.getTypeId(itemsCopy), selectedItem)
        const lastSelection = this.typeModel
        this.typeModel = deepcopy(selectedItem[0].type)
        this.typeModelID = this.typeSelectorDialogModel.selected
        if (this.typeModel !== lastSelection) {
          this.subTypeModel = undefined
          this.subTypeSelectorDialogModel.selected = null
          this.activityModel = undefined
          this.activitiesSelectorDialogModel.selected = null
        }
        this.typeSelectorDialogModel.show = false
      } else if (this.subTypeSelectorDialogModel.show) {
        getItemById(this.subTypeSelectorDialogModel.data, itemsCopy, selectedItem)
        this.subTypeModel = selectedItem[0].name
        this.subTypeModelID = this.subTypeSelectorDialogModel.selected
        this.subTypeSelectorDialogModel.show = false
      } else if (this.placeSelectorDialogModel.show) {
        getItemById(this.placeSelectorDialogModel.data, itemsCopy, selectedItem)
        this.placeModel = deepcopy(selectedItem[0].name)
        this.placeModelID = this.placeSelectorDialogModel.selected
        this.placeSelectorDialogModel.show = false
        this.configureData(selectedItem[0])
      } else if (this.activitiesSelectorDialogModel.show) {
        getItemById(this.activitiesSelectorDialogModel.data, itemsCopy, selectedItem)
        this.activityModel = deepcopy(selectedItem[0].name)
        this.activityModelID = this.activitiesSelectorDialogModel.selected
        this.activitiesSelectorDialogModel.show = false
      }
      this.configActicity()

      this.configEditables()

      // this.cancelDialogAction()
    },
    processChangesData (data) {
      if (data.id_command) {
        const newData = []
        this.tableData.forEach(e => {
          if (e.identificator === parseFloat(data.osId)) {
            switch (data.status) {
              case stateConstants.NOT_SEND:
                e.mdbState = i18n.t('serviceOrder.states.notSend')
                break
              case stateConstants.SEND:
                e.mdbState = i18n.t('serviceOrder.states.send')
                break
              case stateConstants.READY:
                e.mdbState = i18n.t('serviceOrder.states.ready')
                break
              case stateConstants.DELIVERED:
                e.mdbState = i18n.t('serviceOrder.states.delivered')
                break
            }
          }
          newData.push(e)
        })
        this.tableData.cleanAndUpdate(newData)
      } else {
        const newData = []
        this.tableData.forEach(e => {
          if (e.identificator === parseFloat(data.osId)) {
            switch (data.status) {
              case stateConstants.NOT_SEND:
                e.gestyaState = i18n.t('serviceOrder.states.notSend')
                break
              case stateConstants.SEND:
                e.gestyaState = i18n.t('serviceOrder.states.send')
                break
              case stateConstants.READY:
                e.gestyaState = i18n.t('serviceOrder.states.ready')
                break
              case stateConstants.DELIVERED:
                e.gestyaState = i18n.t('serviceOrder.states.delivered')
                break
              case stateConstants.INIT:
                e.gestyaState = i18n.t('serviceOrder.states.stateInit')
                break
              case stateConstants.END:
                e.gestyaState = i18n.t('serviceOrder.states.stateEnd')
                break
            }
          }
          newData.push(e)
        })
        this.tableData.cleanAndUpdate(newData)
      }
    },
    externalPaginator (pageSize, pageNumber) {
      this.queryPageNumber = Number(pageNumber)
      this.queryPageSize = Number(pageSize)
    }
  },
  watch: {
    'getOSData': {
      handler: function (val) {
        if (val) {
          this.processChangesData(val)
        }
      },
      deep: true
    },
    'sectorSelectorDialogModel.selected': {
      handler: function (val) {
        if (val) {
          this.sectorSelectorDialogModel.saveDisabled = false
        } else {
          this.sectorSelectorDialogModel.saveDisabled = true
        }
      },
      deep: true
    },
    'typeSelectorDialogModel.selected': {
      handler: function (val) {
        if (val) {
          this.typeSelectorDialogModel.saveDisabled = false
        } else {
          this.typeSelectorDialogModel.saveDisabled = true
        }
      },
      deep: true
    },
    'subTypeSelectorDialogModel.selected': {
      handler: function (val) {
        if (val) {
          this.subTypeSelectorDialogModel.saveDisabled = false
        } else {
          this.subTypeSelectorDialogModel.saveDisabled = true
        }
      },
      deep: true
    },
    'placeSelectorDialogModel.selected': {
      handler: function (val) {
        if (val) {
          this.placeSelectorDialogModel.saveDisabled = false
        } else {
          this.placeSelectorDialogModel.saveDisabled = true
        }
      },
      deep: true
    },
    'activitiesSelectorDialogModel.selected': {
      handler: function (val) {
        if (val) {
          this.activitiesSelectorDialogModel.saveDisabled = false
        } else {
          this.activitiesSelectorDialogModel.saveDisabled = true
        }
      },
      deep: true
    }

  }
}
